import Cookies from 'js-cookie'
import GlobalConfig from '../globalConfig'
import request from '../request.service'
import { LocalStorage } from '@components/utils/payment-constants'

export interface IBasketItemGroupData {
  brandId?: string
  platformId?: string
  categoryId?: string
  tierId?: string
  brand?: string
  platform?: string
  category?: string
  tier?: string
  image?: string
  kitQty?: number
}

export interface IBulkUpdateBasketLine {
  CustomInfo4?: string
  CustomInfo4Formatted?: string
  CustomInfo5?: string
  CustomInfo5Formatted?: string
  basketId?: string
  customInfo1?: string
  customInfo2?: string
  customInfo3?: string
  parentProductId?: any
  qty?: number
  stockCode?: string
}

export interface IAddToBasketRequest {
  basketId?: string
  basketName?: string
  productId?: string
  qty?: number
  productSpecification?: string
  manualUnitPrice?: number
  displayOrder?: number
  stockCode?: string
  itemType?: number
  postCode?: string
  parentProductId?: string
  isSubscription?: boolean
  subscriptionPlanId?: string
  subscriptionTermId?: string
  userSubscriptionPricing?: number
  giftWrapId?: string
  isGiftWrapApplied?: boolean
  lineItemType?: number
  customInfo1?: string
  customInfo2?: string
  customInfo3?: string
  customInfo4?: string
  customInfo5?: string
  customInfo1Formatted?: string
  customInfo2Formatted?: string
  customInfo3Formatted?: string
  customInfo4Formatted?: string
  customInfo5Formatted?: string
  priceMatchReqId?: string
  isMembership?: boolean
  storeId?: string
  basketItemGroupId?: string
  basketItemGroupData?: IBasketItemGroupData
}

export interface IBulkUpdateBasketRequest {
  id: string
  basketLine: Array<IBulkUpdateBasketLine>
}

export const addToBasketApi = (data: IAddToBasketRequest) => ({
  method: 'PUT',
  headers: {
    ...GlobalConfig.getHeaders(['JSON']),
    is_offline_store: Cookies.get(LocalStorage.Key.IS_OFFLINE_STORE),
  },
  url: GlobalConfig.getApiUrlFromRoot(`api/add-item-cart`),
  cookies: Cookies,
  data,
})

export const bulkUpdateBasketApi = (data: IBulkUpdateBasketRequest) => ({
  method: 'PUT',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/update-bulk-basket`),
  cookies: Cookies,
  data,
})

export class BasketServices {
  static async addToBasket(params: IAddToBasketRequest) {
    const res = await request(addToBasketApi(params))
    return res
  }
  static async bulkUpdateBasket(params: IBulkUpdateBasketRequest) {
    const res = await request(bulkUpdateBasketApi(params))
    return res
  }
}
